<template>
    <div class="Project3DViewer">
        <div class="p3-toucharea" style="left: 0"/>
        <div class="p3-toucharea" style="right: 0"/>
        <svg :id="'p3-loadingscreen-'+project.Model.url" class="p3-loadingscreen" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
              <animateTransform attributeName="transform" attributeType="XML"  type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
            </path>
        </svg>
        <canvas :id="'p3-canvas-'+project.Model.url" class="p3-canvas p3-noswipe" @mouseover="changeCursor('3d')"/>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';


import {Database, Engine, Scene, CubeTexture, Color4, HemisphericLight, 
        Vector3, AssetsManager, ArcRotateCameraPointersInput, VideoTexture } from 'babylonjs';
// import { Database } from '@babylonjs/core';
// import { Engine } from "@babylonjs/core/Engines/engine";
// import { Scene } from "@babylonjs/core/scene";
// import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
// import { Color4 } from "@babylonjs/core/Maths/math.color";
// import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
// import { Vector3 } from "@babylonjs/core/Maths/math";
// import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
// import { ArcRotateCameraPointersInput } from "@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput";
// import { VideoTexture } from "@babylonjs/core/Materials/index";


import "babylonjs-loaders";

export default {
    props: ['project','visible'],
    data: () => ({
        engine: null,
        scene: null,
    }),
    computed: {
    ...mapGetters(['landscape']),
    },

    watch:{
        visible(){
            if(this.visible)
                this.engine.runRenderLoop(()=> { this.renderScene(); });
            else
                this.engine.stopRenderLoop();
        }
    },
    methods: {
        ...mapMutations(['changeCursor']),
        init3DViewer(){
            let canvas = document.getElementById("p3-canvas-"+this.project.Model.url);

            this.engine = new Engine(canvas, true, { xrCompatible: false });
            this.engine.setHardwareScalingLevel(1 / window.devicePixelRatio);
            this.engine.enableOfflineSupport = true;
            Database.IDBStorageEnabled = true;
            Engine.OfflineProviderFactory = (urlToScene, callbackManifestChecked, disableManifestCheck) => {
                return new Database(urlToScene, callbackManifestChecked, true);
            };

            function customLoadingScreen() {}
            customLoadingScreen.prototype.displayLoadingUI = () => {
                document.getElementById("p3-loadingscreen-"+this.project.Model.url).style.opacity = 1;
            };
            customLoadingScreen.prototype.hideLoadingUI = () => {
                document.getElementById("p3-loadingscreen-"+this.project.Model.url).style.opacity = 0;
            };
            this.engine.loadingScreen = new customLoadingScreen();

            this.scene = new Scene(this.engine);
            this.scene.environmentTexture = new CubeTexture.CreateFromPrefilteredData("./Assets/studio.env", this.scene);
            this.scene.clearColor = new Color4(0,0,0,0);
            this.scene.createDefaultCamera(true,true,true);

            new HemisphericLight("HemiLight", new Vector3(0, 1, 0), this.scene);

            let assetsManager = new AssetsManager(this.scene);

            assetsManager.addMeshTask("", "",'https://cvdesign.hshl.de/api/'+this.project.Model.url);

            assetsManager.onFinish = () =>{
                this.scene.createDefaultCamera(true,true,true,3);
                let camera = this.scene.activeCamera;
                camera.inputs.remove(camera.inputs.attached.mousewheel);
                let rightClickInput = new ArcRotateCameraPointersInput();
                rightClickInput.buttons = [3];
                camera.inputs.add(rightClickInput);
                camera.pinchDeltaPercentage = 0.0001;
                camera.panningSensibility = 0;
                camera.alpha = Math.PI - 0.5;
                camera.beta = Math.PI/2 - 0.5;
                camera.radius = this.landscape? camera.radius*0.8 : camera.radius*1.1;
                // this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssaopipeline", camera);
                if(this.project.Screen)
                    this.scene.getMaterialByName('Screen')._emissiveTexture = new VideoTexture("video", 'https://cvdesign.hshl.de/api/'+this.project.Screen.url, this.scene, true, true);
            };

            assetsManager.load();

            let resizeObserver = new ResizeObserver(() => {
                setTimeout(() => {
                    if(this.engine);
                        this.engine.resize();
                }, 0);
            });
            resizeObserver.observe(canvas);
            canvas.addEventListener('touchmove',  e => {e.stopPropagation()});
        },
        renderScene(){
            // this.scene.activeCamera.alpha += 0.001;
            this.scene.render();
        },
    },
    mounted(){
        this.init3DViewer();
    }
}
</script>

<style scoped>
.Project3DViewer{
    padding: 0;
}
.p3-canvas{
    outline: none;
    width: calc(100% + 20px);
    height: calc(100% + 40px);
    background: transparent;
}
.p3-loadingscreen{
    fill: var(--lightVibrant);
    position: absolute;
    background: var(--darkVibrant);
    width: 100%;
    height: 100%;
    transition: opacity .6s ease;
    pointer-events: none;
}
.p3-toucharea{
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 30%;
}
</style>